<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="organizationData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching organization data
      </h4>
      <div class="alert-body">
        No organization found with this organization id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-organizations-list'}"
        >
          Organization List
        </b-link>
        for other organizations.
      </div>
    </b-alert>

    <template v-if="organizationData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <organization-view-organization-info-card :organization-data="organizationData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <organization-view-organization-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <organization-view-organization-permissions-card />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import organizationStoreModule from '../organizationStoreModule'
import OrganizationViewOrganizationInfoCard from './OrganizationViewOrganizationInfoCard.vue'
import OrganizationViewOrganizationTimelineCard from './OrganizationViewOrganizationTimelineCard.vue'
import OrganizationViewOrganizationPermissionsCard from './OrganizationViewOrganizationPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    OrganizationViewOrganizationInfoCard,
    OrganizationViewOrganizationTimelineCard,
    OrganizationViewOrganizationPermissionsCard,

  },
  setup() {
    const organizationData = ref(null)

    const ORGANIZATION_APP_STORE_MODULE_NAME = 'app-organization'

    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.registerModule(ORGANIZATION_APP_STORE_MODULE_NAME, organizationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-organization/fetchOrganization', { id: router.currentRoute.params.id })
      .then(fetchOrganizationResponse => { 
        
         organizationData.value = fetchOrganizationResponse.data;
        
      
      })
      .catch(error => {
        if (error.response.status === 404) {
          organizationData.value = undefined
        }})

    return {
      organizationData,
    }
  },
}
</script>

<style>

</style>
